<template>
  <form @submit.prevent="goToSearch">
    <div class="search--listing-wrapper">
      <search-box ref="searchBox" @selected="goToSearch" @forceSearch="goToSearch" />
      <div v-show="currentKeyword || searchTerm" class="reset--search">
        <button type="button" @click="resetSearch">
          <span class="ion-close"></span>
          <span class="reset--text">Reset Search</span>
        </button>
        <div v-show="isLoading" class="tab_disabled"></div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import LodashMixin from '@/mixins/lodash';
import SearchBox from '@/components/search/search-box.vue';

export default {
  name: 'search-listing',
  mixins: [LodashMixin],
  components: {
    SearchBox,
  },
  data: () => ({}),
  computed: {
    ...mapState({
      isLoading: (state) => state.v2.search.isLoading,
      currentKeyword: (state) => state.v2.search.currentKeyword,
      searchTerm: (state) => state.v2.search.searchTerm,
    }),
  },
  mounted() {},
  methods: {
    resetSearch() {
      if (!this.isLoading) {
        this.$refs.searchBox.clearAll();
        this.goToSearch();
      }
    },
    goToSearch() {
      this.$store.commit('v2/search/SET_PAGE', 1);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
  },
};
</script>

<style scoped></style>
